import React from "react";

const LoanProgressBar = ({ current, adminStage, faciLetterUploaded, type }) => {

    const stages = ["initial", 'approved', "pendingDisbursement", "active", "completed"];

    const currentStageIndex = stages.indexOf(current);
    let progressBarWidth = 100 / (stages.length - 1) * currentStageIndex;

    function getStage(stage, adminStage, faciLetterUploaded) {
        return stage === "initial" ? "In Review" : 
            (stage === 'approved' && adminStage === 'pendingFacilityLetter') ? "Attach Facility Letter" :  
            (stage === "pendingDisbursement" && faciLetterUploaded) ? "Pending Disbursement" :
            stage === "active" ? "Active" :
            "Completed";
    }

    return (
        <div className="w-full mb-12">
            <div className="flex justify-between mb-4">
                <span className="text-base font-medium text-gray-700 dark:text-white/[.7]">
                    {type} Application Progress
                </span>
                <span className="md:hidden lg:hidden text-sm font-medium text-gray-700 dark:text-white">
                    {progressBarWidth}%
                </span>
            </div>

            {/* Progress bar */}
            <div className="relative w-full h-2.5 bg-gray-200 dark:bg-dark-page rounded-full">
                <div className="absolute h-full bg-emerald-400 dark:bg-emerald-300 rounded-full" style={{ width: `${progressBarWidth}%` }}></div>
            </div>
            {/* Progress bar */}

            <div className="hidden md:flex justify-between mt-4">
                {stages.map((stage, index) => (
                    <div key={index} className={`${index <= currentStageIndex && 'text-emerald-400 dark:text-emerald-300'} ${index === currentStageIndex && 'animate-pulse'}`}>
                        {getStage(stage, adminStage, faciLetterUploaded)}
                    </div>
                ))}
            </div>
        </div>
    )

	// return (
	// 	<div className="w-full">
	// 		<div className="flex items-center justify-center mt-4 mb-8">
	// 			{stages.map((stage, index) => (
    //                 <div key={stage} className="flex items-center">
    //                     {index < currentStageIndex ? (
    //                             <div className="w-8 h-8 rounded-full flex items-center justify-center bg-emerald-400 text-white">
    //                                 <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    //                                     <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" d="M5 13l4 4L19 7" />
    //                                 </svg>
    //                             </div>
    //                     ) : (
    //                         <div className={`w-8 h-8 rounded-full flex items-center justify-center border-2 border-gray-300 text-gray-300 ${current === stage && 'animate-pulse'}`}>
    //                             <div className="w-3 h-3 bg-gray-300 rounded-full"></div>
    //                         </div>
    //                     )}
    //                     {index !== stages.length - 1 && (
    //                         <div className={`w-16 border-t-2 ${index < currentStageIndex ? 'border-emerald-400' : 'border-gray-300'}`}></div>
    //                     )}
    //                 </div>
    //             ))}
	// 		</div>
	// 	</div>
	// );
};

export default LoanProgressBar;
