import React from 'react'
import { Topbar, Aside, Main, Information, PageHeader, GCardInfo, Loader } from 'components'
import { Tab } from '@headlessui/react';
import { useGetUser } from 'hooks';

function Edit() {

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const { loading, profile } = useGetUser();

    // design the better loading screen
    if(loading) return <Loader />

    return (
        <main>
            <Topbar />
            
            <div className='pt-20 w-full flex overflow-hidden bg-light-bg dark:bg-dark-page'>
                <Aside />

                <Main>
                    <PageHeader title='Edit Profile' size="text-2xl" />

                    <div className='display_card'>

                        <Tab.Group as="div" vertical className="my-4 grid md:grid-cols-4 grid-cols-1 w-full">
                            <Tab.List className="col-span-1 bg-slate-50 dark:bg-dark-page md:dark:bg-transparent md:bg-transparent p-2 md:p-0 rounded-md">
                                <Tab className={({ selected }) => classNames(`text-slate-700 dark:text-slate-300 hover:bg-hover-link dark:hover:text-slate-700 rounded-md w-full py-2 px-3 my-2 text-left`, selected && `dark:text-gold-500 dark:bg-slate-100/[.2] text-gold-500 bg-hover-link`)}>
                                    Profile Information
                                </Tab>
                                <Tab className={({ selected }) => classNames(`text-slate-700 dark:text-slate-300 hover:bg-hover-link dark:hover:text-slate-700 rounded-md w-full py-2 px-3 my-2 text-left`, selected && `dark:text-gold-500 dark:bg-slate-100/[.2] text-gold-500 bg-hover-link`)}>
                                    GhanaCard Information
                                </Tab>
                            </Tab.List>

                            <Tab.Panels className="md:col-span-3 my-6">
                                <Tab.Panel className="px-0 md:px-8">
                                    <Information profile={profile} />
                                </Tab.Panel>
                                <Tab.Panel className="px-0 md:px-8">
                                    <GCardInfo idNumber={profile?.idNumber} />
                                </Tab.Panel>
                            </Tab.Panels>
                        </Tab.Group>

                    </div>
                </Main>
            </div>
        </main>
    )
}

export default Edit