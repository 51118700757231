// general loan constants
const fileTypesLong = {
    incDoc: 'Incorporation Document',
    solePropId: 'Sole Propietorship ID',
    profile: 'Profile Picture',
    id: 'ID card',
    residence: 'Proof of residence',
    residenceProof: 'Proof of residence',
    mortRent: 'Mortgage or rent', // mandatory housing related expense
    employProof: 'Proof of employment',
    employerMOUFile: 'Employer MOU',
    incomeProof: 'Income Proof',
    groupAgree: 'Group Agreement',
    guarAgreeSigned: 'Signed Guarantor Agreement',
    loanAgree: 'Other Loan Agreement',
    standOrder: 'Standing Order',
    agreeHR: 'Deduction Agreement with HR', // agreement to deduct at source
    busExpense: 'Mandatory business expense',
    capmeMitig: 'CapitalMe Mitigation', // e.g. agent at port, exist customer guarantor, joint service
    other: 'Other Documents',
    otherDocument: 'Other Documents',
    creditBureau: 'Credit Bureau',
    bankOrMomoStatement: 'Bank or MoMo Statement',
    bureauConsent: 'Bureau Consent',
    clientRef: 'Client Reference'
}

const loanProducts = {
    loanme: 'loanme',
    pension : "pension",
    pencash : "pencash",
    achieve: 'achieve',
    promo: 'promo',
    capitalme: 'capitalme',
    groupme: 'groupme',
    cashme: "cashme",
    insurance : "insurance",
    cediscredit : "cediscredit",
    invest : "invest",
    employer : "employer",
    ploc : "ploc",
    salaried : "salaried",
}

const loanPricing = {
    margCostOfFund: 33.25,
    costToAsset: 20,
    expCreditLoss: 10,
    roe: 19,
    //monthDiscount: 0.1,
    capitalme: { monthDiscount: 0 },
    loanme: { monthDiscount: 0 },
    promo: { monthDiscount: 0 },
    achieve: { monthDiscount: 0 },
    pension: { monthDiscount: 0 },
    insurance: { monthDiscount: 0 },
    invest: { monthDiscount: 0 },
    employer: { monthDiscount: 0 },
    ploc: { monthDiscount: 0 },
    salaried: { monthDiscount: 0 },
};

const loanTypes = {
    loanme: 'loanme',
    pension : "pension",
    pencash : "pencash",
    achieve: 'achieve',
    promo: 'promo',
    capitalme: 'capitalme',
    groupme: 'groupme',
    cashme: "cashme",
    insurance : "insurance",
    cediscredit : "cediscredit",
    invest : "invest",
    employer : "employer",
    ploc : "ploc",
    salaried : "salaried",
};

// score to ratings 
const scoreToRatingMins = {
    90: 1,
    80: 2,
    70: 3,
    60: 4,
    50: 5,
    0: 6
};

// risk ratings
const riskRates = {
    1 : 4.36,
    2 : 6.36,
    3 : 8.36,
    4 : 10.36,
    5 : 14.36,
    6 : 19.36,
}

// Interest Rate for Each Loan
const interestRate = {
    loanme : 4,
    loanmeCredit : 4,
    achieve : 3,
    achieveCap : 0.42,
    promo : 3,
    capitalme : 6,
    capitalmeTopUp : 6,
    creditTopUp : 4,
    cashme: 5,
    cediscredit : 5, // 6
    creditBuilder : 4,
    investmentAchieve : 2.5,
    investmentPetra : 2.5,
    employer : 4,
    pension : 1.8,
    pensionCash : 3,
    pensionCredit : 2.5,
    pensionUnsecured : 3.5,
    ploc : 0,
    salaried : 4,
};

// Loan amount limits
const loanAmtLimits = {
    loanme : 20000, // 5000
    personalLoanCreditLimit : 3000, // loanme cediscredit
    businessLoanCreditLimit : 3000, // capitalme cediscredit
    promoLoanLimit : 10000, // promo loans
    achieveLoanLimit : 20000, // achieve loans
    businessLoanLimit : 20000, // 10000
    cashmeLoanLimit : 3000,
    pensionCashLoanLimit : 20000,
    creditCashLoanLimit : 10000, // 3000
    investCashLoanLimit : 20000,
    employerLoanLimit : 10000,
    pensionLoanLimit : 20000,
    pensionCreditLoanLimit : 3000,
    salariedLoanLimit : 20000,
}

// processing, insurance fees
const processingfees = {
    // processing fee
    loanme : 2, // loanme for now
    capitalme : 3,
    pension : 2,
    employer : 2,
    promo : 2,
    creditBuilder : 3,
    // processingFeeCedisLoan : 3, // not applied yet
    investmentAchieve : 3,
    investmentPetra : 3.5,
    penionCash : 3,
    salaried : 3,

    // life insurance fee
    lifeInsurance : 0.65,
}

const perMonthPaymentFractions = {
    Daily : 1 / 30,
    Weekly: 1 / 4,
    Biweekly: 1 / 2,
    Monthly: 1,
    Quarterly: 3
};

const creditScoreThresholds = {
    loanme : 50,
    capitalme : 80,
    investment : 50,
    promo : 50,
    employer : 40,
    pension : 50,
    pencash : 40,
    cediscredit : 50,
    cashme : 60,
    salaried : 50,
}



const workYears = {
    above10 : 35,
    max10 : 30,
    max5 : 25,
    below1 : 5
}

const incomeScores = {
    above10k : 40,
    max10k : 30,
    max5k : 20,
    below2k : 10
}

const capitalMeMomoAverage = {
    above8k : 25,
    max8k : 20,
    max6k : 15,
    max4k : 10,
    below2k : 5
}

const capitalMeIncomeRange = {
    above15k : 25,
    max15k : 20,
    max10k : 15,
    max5k : 10,
    below3k : 5
}


// cashme constants
const yearsInBusiness = {
    above3 : 30,
    max2 : 20,
    max1 : 0
}

const cashMeIncomeFlow = {
    above3k : 25,
    max3k : 20,
    max2k : 15,
    max1k : 10,
    below500 : 5
}


export {
    fileTypesLong,
    loanProducts,
    loanPricing,
    loanTypes,
    scoreToRatingMins,
    riskRates,
    interestRate,
    loanAmtLimits,
    processingfees,
    perMonthPaymentFractions,
    creditScoreThresholds,

    // loanMe constants
    workYears,
    incomeScores,
    capitalMeMomoAverage,
    capitalMeIncomeRange,

    // cashme constants
    yearsInBusiness,
    cashMeIncomeFlow
}